import React from 'react'
import { Link } from 'gatsby'
import CtaRow from 'components/cta-row'
import { documentIcon, documentTemplateIcon } from 'images/hulp/tools'
import Helmet from 'react-helmet'
import Layout from '../../layouts'


const WhitepapersPage = ({location}) => (
  <Layout location={location}>
  <main className="tools animated fadeInPage">

    <Helmet>
      <title>Handige whitepapers over salarisadministratie & HR | Employes</title>
      <meta name="description" content="Download onze gratis whitepapers en gidsen over salarisadministratie en HR." />
      <meta itemprop="name" content="Handige whitepapers over salarisadministratie & HR | Employes" />
      <meta itemprop="description" content="Download onze gratis whitepapers en gidsen over salarisadministratie en HR."/>
      <meta itemprop="image" content="/static/meta-whitepapers.jpg" />

      {/* Windows tags */}
      <meta name="msapplication-TileColor" content="#ffffff" />
      <meta name="msapplication-TileImage" content="static/favicon/mstile-144x144.png" />

      {/* OpenGraph tags */}
      <meta property="og:url" content="" />
      <meta property="og:type" content="website" />
      <meta property="og:title" content="Handige whitepapers over salarisadministratie & HR | Employes" />
      <meta property="og:description" content="Download onze gratis whitepapers en gidsen over salarisadministratie en HR." />
      <meta property="og:image" content="https://employes.nl/static/meta-whitepapers.jpg" />

      {/* Twitter Card tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content="Handige whitepapers over salarisadministratie & HR | Employes" />
      <meta name="twitter:description" content="Download onze gratis whitepapers en gidsen over salarisadministratie en HR." />
      <meta name="twitter:image" content="https://employes.nl/static/meta-whitepapers.jpg" />
    </Helmet>

    <header className="padding-xl">
      <div className="container-md">
        <diV className="grid yg center text-center">
          <div className="col-12">
            <h2 className="eyebrow">Hulp</h2>
            <h1>Whitepapers</h1>
            <p className="streamer center">Download onze gratis whitepapers en gidsen over salarisadministratie en HR.</p>
          </div>
        </diV>
      </div>
    </header>

    <section className="tools-cards padding-xxl">
      <div className="container-md">
        <div className="grid yg">


        <div className="col-6 margin-l-bottom content-box">
          <div className="tools-cards-single card">
            <div className="grid">
              <div className="col-2 no-pad file-icon-image">
                <img src={documentTemplateIcon}/>
              </div>

              <div className="col-10">
                <p className="eyebrow">Whitepaper</p>
                <h3 className="margin-xxs-bottom">Hoe begin je met salarisadministratie?</h3>
                <p className="margin-s-bottom">Ga je voor het eerste werknemers aannemen en moet je je salarisadministratie opzetten? Ontdek hoe je dit in een aantal eenvoudige stappen doet.</p>
                <Link to="/whitepapers/beginnen-met-salarisadminstratie/" className="btn secondary">Bekijk <span className="arrow right"></span></Link>
              </div>
            </div>
          </div>
        </div>

        <div className="col-6 margin-l-bottom content-box">
          <div className="tools-cards-single card">
            <div className="grid">
              <div className="col-2 no-pad file-icon-image">
                <img src={documentTemplateIcon}/>
              </div>

              <div className="col-10">
                <p className="eyebrow">Whitepapers</p>
                <h3 className="margin-xxs-bottom">Hoe werkt Employes voor werknemers?</h3>
                <p className="margin-s-bottom">Een uitleg over hoe je Employes gebruikt als werknemer. Met uitleg over het indienen van declaraties, verlof en verzuim.</p>
                <Link to="handleiding-voor-werknemers" className="btn secondary">Bekijk <span className="arrow right"></span></Link>
              </div>
            </div>
          </div>
        </div>

          <div className="col-6 margin-l-bottom content-box">
            <div className="tools-cards-single card">
              <div className="grid">
                <div className="col-2 no-pad file-icon-image">
                  <img src={documentTemplateIcon}/>
                </div>

                <div className="col-10">
                  <p className="eyebrow">Whitepaper</p>
                  <h3 className="margin-xxs-bottom">Hoe word je een held in HR administratie?</h3>
                  <p className="margin-s-bottom">Ben je ondernemer of een HR professionals binnen een snelgroeiende organisatie? Lees dan onze whitepaper over het optimaliseren van je HR-processen.</p>
                  <Link to="/whitepapers/hoe-word-je-een-held-in-hr/" className="btn secondary">Bekijk <span className="arrow right"></span></Link>
                </div>
              </div>
            </div>

          </div>



        </div>
      </div>

    </section>


  </main>
  </Layout>
)

export default WhitepapersPage
